body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.airtable-container {
  width: 60%;
  margin: 10% auto;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 16px 24px 0px, rgba(0, 0, 0, 0.1) 0px 2px 6px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 8px;
  padding: 2rem 1rem;
}

@media (max-width: 900px) {
  .airtable-container {
    margin: 15% auto;
  }
}

@media (max-width: 450px) {
  .airtable-container {
    width: 80%;
    margin: 20% auto;
  }
}